import SEO from '@/seo';
import { signIn } from '@holo/auth/react';
import { Link } from '@holo/link';
import { Button, H2, Icon, Spinner, Text, XStack, YStack } from '@holo/ui';
import { Apple, Facebook, Google } from '@holo/ui/assets';
import type { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: await serverSideTranslations(locale ?? 'en'),
  };
};

type Providers = 'google' | 'facebook' | 'apple';

const Login = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const [loading, setLoading] = useState({
    google: false,
    apple: false,
    facebook: false,
  });

  const { return_to: returnTo } = router.query;

  const handleSignIn = (provider: Providers) => {
    setLoading((prev) => ({ ...prev, [provider]: true }));

    const callbackUrl = returnTo ? `${window.location.origin}/${returnTo}` : window.location.origin;

    signIn(provider, {
      callbackUrl,
    });
  };

  useEffect(() => {
    if (router.query?.error) {
      toast.error(t('login.errorTitle'), {
        description: t('login.errorDescription'),
        icon: <Icon icon="alertTriangle" size={16} />,
      });
    }
  }, [router]);

  return (
    <YStack minHeight="100vh" jc="center" ai="center">
      <SEO
        title={t('login.title')}
        description={t('login.description')}
        openGraph={{
          type: 'website',
          title: t('login.title'),
          description: t('login.description'),
        }}
      />

      <YStack $md={{ maxWidth: 335 }} w="100%" paddingHorizontal="$5" mt={-100}>
        <H2 textAlign="center" mb="$7">
          Log in to Holo
        </H2>

        <Button
          mb="$3"
          bg="$subtlePale"
          textProps={{ color: '$contrast' }}
          hoverStyle={{ backgroundColor: '$border' }}
          focusStyle={{ backgroundColor: '$border', borderColor: '$border' }}
          height={50}
          fontSize="$14"
          onPress={() => handleSignIn('google')}
        >
          <XStack ai="center">
            {loading.google ? <Spinner color="$contrast" /> : <Google />}
            <Text ml="$2">Continue with Google</Text>
          </XStack>
        </Button>

        <Button
          mb="$3"
          bg="$subtlePale"
          textProps={{ color: '$contrast' }}
          hoverStyle={{ backgroundColor: '$border' }}
          focusStyle={{ backgroundColor: '$border', borderColor: '$border' }}
          height={50}
          fontSize="$14"
          onPress={() => handleSignIn('apple')}
        >
          <XStack ai="center">
            {loading.apple ? <Spinner color="$contrast" /> : <Apple />}
            <Text ml="$2">Continue with Apple</Text>
          </XStack>
        </Button>

        <Button
          mb="$3"
          bg="$subtlePale"
          textProps={{ color: '$contrast' }}
          hoverStyle={{ backgroundColor: '$border' }}
          focusStyle={{ backgroundColor: '$border', borderColor: '$border' }}
          height={50}
          fontSize="$14"
          onPress={() => handleSignIn('facebook')}
        >
          <XStack ai="center">
            {loading.facebook ? <Spinner color="$contrast" /> : <Facebook />}
            <Text ml="$2">Continue with Facebook</Text>
          </XStack>
        </Button>

        <XStack borderTopWidth={1} borderColor="$border" jc="center" mt="$5" pt="$5">
          <Link href={returnTo ? `/login/email?return_to=${returnTo}` : '/login/email'}>
            <Text>Continue with Email →</Text>
          </Link>
        </XStack>
      </YStack>
    </YStack>
  );
};

export default Login;
