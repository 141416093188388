import { skeleton } from '../../skeleton';

type Props = {
  loading?: boolean;
};

const Google = ({ loading }: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 256 262"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
    className={skeleton.className('line', loading)}
  >
    <path
      d="M255.9 133.5c0-10.8-.9-18.6-2.8-26.7H130.6v48.4h71.9a63.8 63.8 0 0 1-26.7 42.4l-.2 1.6 38.7 30 2.7.3c24.7-22.8 38.9-56.3 38.9-96"
      fill="#4285F4"
    />
    <path
      d="M130.6 261.1c35.2 0 64.8-11.6 86.4-31.6l-41.2-32c-11 7.8-25.8 13.1-45.2 13.1a78.6 78.6 0 0 1-74.3-54.2l-1.5.1-40.3 31.2-.6 1.5C35.4 231.8 79.5 261 130.6 261"
      fill="#34A853"
    />
    <path
      d="M56.3 156.4a80.4 80.4 0 0 1-.2-51.7V103L15.3 71.3l-1.4.6a130.7 130.7 0 0 0 0 117.3l42.4-32.8"
      fill="#FBBC05"
    />
    <path
      d="M130.6 50.5c24.5 0 41 10.6 50.4 19.4L218 34c-22.8-21-52.2-34-87.4-34C79.5 0 35.4 29.3 13.9 72l42.2 32.7a79 79 0 0 1 74.4-54.2"
      fill="#EB4335"
    />
  </svg>
);

export default Google;
